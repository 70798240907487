<template>
  <div>
    <v-jsoneditor v-model="computedCommands.command" :plus="false" :options="options" />
    <v-switch v-model="computedCommands.first_value" :disabled="!editable" hide-details label="Primeiro valor" />
    <v-switch v-model="computedCommands.atemporal" :disabled="!editable" hide-details label="Atemporal" />

    <div v-if="computedCommands.atemporal === false">
      <v-text-field id="data_field" v-model="computedCommands.date_field" :disabled="!editable" outline single-line label="date_field" />
      <v-text-field
        id="date_filter_format"
        v-model="computedCommands.date_filter_format"
        :disabled="!editable"
        outline
        single-line
        label="date_filter_format"
      />
      <v-text-field
        id="local_date_parser"
        v-model="computedCommands.local_date_parser"
        hide-details
        :disabled="!editable"
        outline
        single-line
        label="local_date_parser"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    commands: Object,
    editable: Boolean
  },
  data: () => ({
    options: { mode: 'code', mainMenuBar: false }
  }),
  computed: {
    computedCommands: {
      get() {
        return this.commands
      },
      set(commands) {
        this.$emit('update:commands', commands)
      }
    }
  }
}
</script>
